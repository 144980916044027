import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "../Stylesheets/NeoForm.css";

import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import AuthManager from "../Auth/AuthManager";
import { BASE_URL } from "../Constants";
import { DEBUG_MODE_ENABLED } from "../Constants";
import { Link } from "react-router-dom";
import ErrorBox from "../Components/ErrorBox";
import NeoButton from "../Button/NeoButton";
import Cookies from "js-cookie";

class Login extends React.Component {
	state = {
		email: DEBUG_MODE_ENABLED ? "tester@weac.de" : "",
		password: DEBUG_MODE_ENABLED ? "teamweac" : "",
		incorrectCredentials: false,
		errorMessage: null,
		isSending: false,
	};

	constructor(props) {
		super(props);

		this.handleEmailFieldChange = this.handleEmailFieldChange.bind(this);
		this.handlePasswordFieldChange = this.handlePasswordFieldChange.bind(this);

		this.login = this.login.bind(this);
	}

	handleEmailFieldChange(event) {
		this.setState({ email: event.target.value });
	}

	handlePasswordFieldChange(event) {
		this.setState({ password: event.target.value });
	}

	isVendorPage() {
		return this.props.history.location.pathname.includes("/vendor");
	}

	login() {
		this.setState({ isSending: true });

		axios
			.post(
				BASE_URL +
					(this.isVendorPage() ? "/vendor/auth/login" : "/user/auth/login"),
				{
					email: this.state.email,
					password: this.state.password,
				}
			)
			.then((result) => {
				const loginResult = result.data;

				let authManager = new AuthManager();
				authManager.login(loginResult.access_token, loginResult.expires_at);

				if (this.isVendorPage()) {
					if (Cookies.get("CurrentStoreID") === undefined) {
						Cookies.set("CurrentStoreName", loginResult.main_branch.name);
						Cookies.set("CurrentStoreID", loginResult.main_branch.id);
					}
				}

				if (this.props.location.state === undefined) {
					this.props.history.push(this.isVendorPage() ? "/vendor" : "/");
				} else {
					this.props.history.push(this.props.location.state.from.pathname);
				}
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Anmelden" />

				<div className="neo-content">
					<p className="neo-bar-title">Anmelden</p>

					<input
						type="text"
						placeholder="E-Mail Adresse"
						value={this.state.email}
						onChange={this.handleEmailFieldChange}
					/>
					<input
						type="password"
						placeholder="Passwort"
						value={this.state.password}
						onChange={this.handlePasswordFieldChange}
					/>

					{this.ErrorsView()}

					<NeoButton
						title="Anmelden"
						onClick={this.login}
						showLoadingState={this.state.isSending}
						disabled={
							this.state.email.length === 0 || this.state.password.length === 0
						}
					/>

					<Link
						to={
							this.isVendorPage() ? "/vendor/forgotPassword" : "/forgotPassword"
						}
					>
						Passwort vergessen?
					</Link>
				</div>

				<Footer />
			</div>
		);
	}
}

export default Login;

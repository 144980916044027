import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";

import Footer from "./Components/Footer";
import { useHistory } from "react-router-dom";
import MenuButton from "./MenuButton";

function Onboarding(props) {
	let history = useHistory();

	console.log(props.location);

	function showLogin() {
		if (isVendorPage()) {
			history.push("/vendor/login");
		} else {
			history.push({
				pathname: "/login",
				state: props.location.state,
			});
		}
	}

	function showRegister() {
		if (isVendorPage()) {
			history.push("/vendor/register");
		} else {
			const { id } = props.match.params;

			if (id === undefined) {
				history.push({
					pathname: "/register",
					state: props.location.state,
				});
			} else {
				history.push({
					pathname: "/register/" + id,
					state: props.location.state,
				});
			}
		}
	}

	function isVendorPage() {
		return history.location.pathname.includes("/vendor");
	}

	return (
		<div className="neo-app">
			<div className="neo-content">
				<div
					className="neo-logo"
					style={{
						backgroundImage: isVendorPage()
							? "url('/VendorLogoSmall.png'"
							: "url('/LogoLarge.png'",
					}}
				/>

				{isVendorPage() ? (
					<p className="neo-text">
						Willkommen beim German SmartLease Händlerportal.
					</p>
				) : (
					<p className="neo-text">
						Willkommen bei German SmartLease, der intelligenten Leasing-Lösung
						in deiner Nähe.
					</p>
				)}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				<button className="neo-button" onClick={showLogin}>
					Anmelden
				</button>
				<button className="neo-button" onClick={showRegister}>
					Konto erstellen
				</button>

				<div className="neo-spacer20" />

				{!isVendorPage() ? (
					<MenuButton
						title="Händler in der Nähe"
						icon="Menu_Shops.png"
						onClick={showShops}
					/>
				) : null}

				{!isVendorPage() && (
					<div>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />

						<p className="neo-text" style={{ marginBottom: "15px" }}>
							Sie sind Händler?
						</p>

						<button className="neo-button" onClick={showVendorOnboarding}>
							Zum Händlerportal
						</button>
					</div>
				)}

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
			</div>

			<Footer />
		</div>
	);

	function showVendorOnboarding() {
		history.push("/vendor/welcome");
	}

	function showShops() {
		history.push("/shops");
	}
}

export default Onboarding;

import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import { useState, useEffect } from "react";
import NeoButton from "../Button/NeoButton";
import ErrorBox from "../Components/ErrorBox";
import { useHistory } from "react-router-dom";

function StoreForm(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [errors, setErrors] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const { id } = props.match.params;

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		web: "",
		street: "",
		zip: "",
		city: "",
	});

	let history = useHistory();

	useEffect(() => {
		if (id !== undefined) {
			fetchStore(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function fetchStore(id) {
		setIsLoading(true);

		axios
			.get(BASE_URL + "/vendor/branch/" + id)
			.then((result) => {
				const data = result.data.data;
				console.log(data);
				setFormData({
					name: data.name,
					email: data.email,
					phone: data.telephone,
					web: data.web,
					street: data.address.street,
					zip: data.address.zip,
					city: data.address.city,
				});

				setIsLoading(false);
			})
			.catch(() => {});
	}

	function LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	function ErrorsView() {
		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	function handleChange(event) {
		let value = event.target.value;

		setFormData({
			...formData,
			[event.target.name]: value,
		});
	}

	function createStore() {
		setIsSending(true);

		var url = "/vendor/branch";

		if (id !== undefined) {
			url = "/vendor/branch/" + id;
		}

		axios
			.post(BASE_URL + url, {
				email: formData.email,
				name: formData.name,
				telephone: formData.phone,
				web: formData.web,
				street: formData.street,
				zip: formData.zip,
				city: formData.city,
			})
			.then(() => {
				history.push("/vendor/stores");
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					setIsSending(false);
					setErrors(error.response.data.errors);
					setErrorMessage(error.response.data.message);
				}
			});
	}

	function deleteStore() {
		axios
			.get(BASE_URL + "/vendor/branch/" + id + "/delete")
			.then(() => {
				history.push("/vendor/stores");
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					setIsSending(false);
					setErrors(error.response.data.errors);
					setErrorMessage(error.response.data.message);
					setShowDeleteConfirmation(false);
				}
			});
	}

	function Form() {
		return (
			<div>
				<input
					type="text"
					placeholder="Name"
					name="name"
					value={formData.name}
					onChange={handleChange}
				/>
				<input
					type="text"
					placeholder="E-Mail"
					name="email"
					value={formData.email}
					onChange={handleChange}
				/>
				<input
					type="text"
					placeholder="Telefon"
					name="phone"
					value={formData.phone}
					onChange={handleChange}
				/>
				<input
					type="text"
					placeholder="Webseite"
					name="web"
					value={formData.web}
					onChange={handleChange}
				/>
				<input
					type="text"
					placeholder="Straße"
					name="street"
					value={formData.street}
					onChange={handleChange}
				/>
				<input
					type="text"
					placeholder="PLZ"
					name="zip"
					value={formData.zip}
					onChange={handleChange}
				/>
				<input
					type="text"
					placeholder="Ort"
					name="city"
					value={formData.city}
					onChange={handleChange}
				/>

				{ErrorsView()}

				<NeoButton
					showLoadingState={isSending}
					title="Speichern"
					onClick={createStore}
					disabled={
						formData.name.length === 0 ||
						formData.street.length === 0 ||
						formData.zip.length === 0 ||
						formData.city.length === 0
					}
				/>

				{id !== undefined && (
					<NeoButton
						title="Löschen..."
						isDestructive={true}
						onClick={() => setShowDeleteConfirmation(true)}
					/>
				)}
			</div>
		);
	}

	return (
		<div className="neo-app">
			<NavigationBar title="Filiale erstellen" />

			<div className="neo-content">
				<p className="neo-bar-title">
					{id === undefined ? "Filiale erstellen" : "Filiale bearbeiten"}
				</p>

				{isLoading ? LoadingView() : Form()}

				{showDeleteConfirmation ? (
					<div className="modal">
						<div className="flexContainer-vertical">
							<div className="modal-content" style={{ padding: "20px" }}>
								<p className="neo-text">
									Möchten Sie diese Filiale wirklich löschen? Alle derzeitigen
									Anfragen werden abgebrochen.
								</p>

								<NeoButton
									isDestructive={true}
									title="Löschen"
									onClick={deleteStore}
								/>

								<NeoButton
									isDestructive={false}
									title="Abbrechen"
									onClick={() => setShowDeleteConfirmation(false)}
								/>
							</div>
						</div>
					</div>
				) : null}
			</div>

			<Footer />
		</div>
	);
}

export default StoreForm;

import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";

class PrivacyBanner extends React.Component {
	state = {
		display: true,
	};

	constructor(props) {
		super(props);

		this.close = this.close.bind(this);
	}

	componentDidMount() {
		if (localStorage.getItem("CookieBannerConfirmed")) {
			this.setState({ display: false });
		}
	}

	render() {
		return (
			<div
				className="neo-privacyBanner"
				style={{
					display: this.state.display ? "true" : "none",
					bottom: "30px",
				}}
			>
				<p
					className="neo-smallTitle"
					style={{ color: "black", textShadow: "none", textAlign: "left" }}
				>
					Datenschutz & Cookies
				</p>
				<p
					className="neo-text"
					style={{ fontSize: "15px", color: "gray", textShadow: "none" }}
					id="leading"
				>
					Diese Seite verwendet Cookies zum Sichern Ihrer Anmeldedaten. Bei der
					Nutzung dieser Webseite stimmen Sie unseren Datenschutzbestimmungen
					zu.
				</p>

				<div className="neo-privacyBanner-button" onClick={this.close}>
					OK
				</div>
			</div>
		);
	}

	close() {
		this.setState({ display: false });

		localStorage.setItem("CookieBannerConfirmed", 1);
	}
}

export default PrivacyBanner;

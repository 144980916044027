import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthManager from "./Auth/AuthManager";

import Main from "./Main";
import Onboarding from "./Onboarding";
import Profile from "./Profile/Profile";
import IDCardUploadForm from "./Profile/IDCardUploadForm";
import Imprint from "./Imprint";
import Login from "./Auth/Login";
import ExampleContract from "./ExampleContract";
import ShopsList from "./Shop/ShopsList";
import Contact from "./Contact/Contact";
import Offers from "./Offers";
import OfferDetails from "./OfferDetails";
import PageNotFound from "./Components/PageNotFound";
import CreateAccount from "./Auth/CreateAccount";
import CreateAgreementAccount from "./Auth/CreateAgreementAccount";
import CreateVendorAccount from "./Auth/CreateVendorAccount";
import ChangePassword from "./Profile/ChangePassword";
import ForgotPassword from "./Auth/ForgotPassword";
import SetNewPassword from "./Auth/SetNewPassword";
import AcceptOffer from "./AcceptOffer";
import ReviewCustomerOffer from "./CustomerOffer/ReviewCustomerOffer";
import DeleteAccount from "./DeleteAccount";
import QRScanner from "./QRScanner";
import CreateOffer from "./CreateOffer";
import CreateCustomerOffer from "./CustomerOffer/CreateCustomerOffer";
import VendorProfile from "./Profile/VendorProfile";
import VendorOffers from "./VendorOffers";
import VendorOfferDetails from "./VendorOfferDetails";
import WithdrawOffer from "./WithdrawOffer";
import VendorIDChecks from "./VendorIDChecks";
import PrivacyBanner from "./Components/PrivacyBanner";
import UploadDocuments from "./UploadDocuments";
import UploadData from "./UploadData";
import VendorUploadData from "./VendorUploadData";
import CompanyGroupUsersView from "./CompanyGroups/CompanyGroupUsersView";
import AcceptInviteView from "./CompanyGroups/AcceptInviteView";
import ScanResultView from "./ScanResultView";
import ScanResultView_QR from "./ScanResultView_QR";
import MyCompanyView from "./Profile/MyCompanyView";
import Stores from "./Profile/Stores";
import StoreForm from "./Profile/StoreForm";
import Expertenrechner from "./rechner/Expertenrechner";

function Routing() {
	function CustomerRoutes() {
		return (
			<Switch>
				<PrivateRoute exact path="/" component={Main} />
				<Route exact path="/welcome/:id" component={ScanResultView} />
				<Route exact path="/welcome/:id/qr" component={ScanResultView_QR} />

				<Route exact path="/welcome" component={Onboarding} />
				<Route exact path="/imprint" component={Imprint} />
				<Route exact path="/mustervertrag" component={ExampleContract} />
				<PrivateRoute path="/contact" component={Contact} />

				<Route exact path="/login" component={Login} />
				<Route exact path="/register/:id" component={CreateAccount} />
				<Route exact path="/register" component={CreateAccount} />
				<Route exact path="/rahmenvertrag" component={CreateAgreementAccount} />
				<Route exact path="/forgotPassword" component={ForgotPassword} />
				<Route exact path="/forgotPassword/set" component={SetNewPassword} />

				<PrivateRoute exact path="/profile" component={Profile} />
				<PrivateRoute
					exact
					path="/profile/id-card"
					component={IDCardUploadForm}
				/>
				<PrivateRoute path="/profile/password" component={ChangePassword} />
				<PrivateRoute path="/profile/delete" component={DeleteAccount} />
				<PrivateRoute
					exact
					path="/profile/companygroup"
					component={MyCompanyView}
				/>
				<PrivateRoute
					path="/profile/companygroup/users"
					component={CompanyGroupUsersView}
				/>
				<PrivateRoute path="/invite/:id" component={AcceptInviteView} />


				<PrivateRoute path="/rechner/expertenrechner" component={Expertenrechner} />

				<Route path="/shops" component={ShopsList} />

				<PrivateRoute
					exact
					path="/direct/:uuid"
					component={CreateCustomerOffer}
				/>
				<PrivateRoute
					exact
					path="/direct/:uuid/:id"
					component={CreateCustomerOffer}
				/>

				<PrivateRoute exact path="/offers/direct" component={Offers} />
				<PrivateRoute
					exact
					path="/offers/direct/:id"
					component={OfferDetails}
				/>



				<PrivateRoute exact path="/offers" component={Offers} />
				<PrivateRoute exact path="/offers/:id" component={OfferDetails} />

				<PrivateRoute
					exact
					path="/offers/:id/data"
					component={UploadData}
				/>

				<PrivateRoute
					exact
					path="/offers/company_group/:id"
					component={OfferDetails}
				/>
				<PrivateRoute exact path="/offers/:id/accept" component={AcceptOffer} />

				<Route path="*" component={PageNotFound} />
			</Switch>
		);
	}

	function VendorRoutes() {
		return (
			<Switch>
				<PrivateRoute exact path="/vendor" component={Main} />
				<Route exact path="/vendor/welcome" component={Onboarding} />
				<Route exact path="/vendor/imprint" component={Imprint} />
				<Route exact path="/vendor/mustervertrag" component={ExampleContract} />
				<PrivateRoute path="/vendor/contact" component={Contact} />

				<Route exact path="/vendor/login" component={Login} />
				<Route exact path="/vendor/register" component={CreateVendorAccount} />
				<Route exact path="/vendor/forgotPassword" component={ForgotPassword} />
				<Route
					exact
					path="/vendor/forgotPassword/set"
					component={SetNewPassword}
				/>

				<PrivateRoute exact path="/vendor/profile" component={VendorProfile} />
				<PrivateRoute exact path="/vendor/stores" component={Stores} />
				<PrivateRoute
					exact
					path="/vendor/stores/create"
					component={StoreForm}
				/>
				<PrivateRoute exact path="/vendor/stores/:id" component={StoreForm} />

				<PrivateRoute
					path="/vendor/profile/password"
					component={ChangePassword}
				/>
				<PrivateRoute path="/vendor/profile/delete" component={DeleteAccount} />

				<PrivateRoute path="/vendor/scan" component={QRScanner} />

				<PrivateRoute exact path="/vendor/idCheck" component={VendorIDChecks} />

				<PrivateRoute
					exact
					path="/vendor/offers/handover"
					component={VendorOffers}
				/>

				<PrivateRoute
					exact
					path="/vendor/offers/direct"
					component={VendorOffers}
				/>
				<PrivateRoute exact path="/vendor/offers" component={VendorOffers} />
				<PrivateRoute
					exact
					path="/vendor/offers/create/:id"
					component={CreateOffer}
				/>
				<PrivateRoute
					exact
					path="/vendor/offers/review/:id"
					component={ReviewCustomerOffer}
				/>
				<PrivateRoute
					exact
					path="/vendor/offers/:id/accept"
					component={AcceptOffer}
				/>
				<PrivateRoute
					exact
					path="/vendor/offers/:id/withdraw"
					component={WithdrawOffer}
				/>
				<PrivateRoute
					exact
					path="/vendor/offers/:id/upload"
					component={UploadDocuments}
				/>
				<PrivateRoute
					exact
					path="/vendor/offers/:id/data"
					component={VendorUploadData}
				/>
				<PrivateRoute
					exact
					path="/vendor/offers/:id"
					component={VendorOfferDetails}
				/>

				<Route path="*" component={PageNotFound} />
			</Switch>
		);
	}

	return (
		<div>
			<Switch>
				<Route path="/vendor" component={VendorRoutes} />
				<Route path="/" component={CustomerRoutes} />
			</Switch>

			<PrivacyBanner />
		</div>
	);
}

function PrivateRoute({ component: Component, authed, ...rest }) {
	let authManager = new AuthManager();

	return (
		<Route
			{...rest}
			render={(props) =>
				authManager.isAuthenticated() === true ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: props.location.pathname.includes("vendor")
								? "/vendor/welcome"
								: "/welcome",
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);
}

export default Routing;

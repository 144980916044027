import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";
import "./Button/NeoButton.css";
import Footer from "./Components/Footer";
import MenuButton from "./MenuButton";
import AuthManager from "./Auth/AuthManager";
import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL, INSURANCE_URL } from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import NavigationBar from "./NavigationBar/NavigationBar";
import NeoButton from "./Button/NeoButton";
import MultiStoreBanner from "./Components/MultiStoreBanner";

class Main extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isLoadingInvites: true,
			invites: [],
		};

		this.showOffers = this.showOffers.bind(this);
		this.showVendorDirectOffers = this.showVendorDirectOffers.bind(this);
		this.showVendorDirectHandovers = this.showVendorDirectHandovers.bind(this);
		this.showCustomerDirectOffers = this.showCustomerDirectOffers.bind(this);
		this.showShops = this.showShops.bind(this);
		this.showContact = this.showContact.bind(this);
		this.showProfile = this.showProfile.bind(this);
		this.showMyCompany = this.showMyCompany.bind(this);
		this.showCalc = this.showCalc.bind(this);
		this.showQRScan = this.showQRScan.bind(this);
		this.logout = this.logout.bind(this);
		this.showIDCheck = this.showIDCheck.bind(this);
		this.loadInvites = this.loadInvites.bind(this);
		this.acceptInvite = this.acceptInvite.bind(this);
		this.declineInvite = this.declineInvite.bind(this);
	}

	isVendorPage() {
		return this.props.history.location.pathname.includes("/vendor");
	}

	componentDidMount() {
		this.loadDashboard();

		if (this.isVendorPage()) {
			this.setState({ isLoadingInvites: false });
		} else {
			this.loadInvites();
		}
	}

	loadDashboard() {
		axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
			"AccessToken"
		)}`;

		let branchID = Cookies.get("CurrentStoreID") ?? "";

		axios
			.get(
				BASE_URL +
					(this.isVendorPage()
						? "/vendor/dashboard?branch=" + branchID
						: "/user/dashboard")
			)
			.then((result) => {
				const dashboard = result.data;

				this.setState({ dashboard, isLoading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	loadInvites() {
		axios
			.get(BASE_URL + "/user/company/invites")
			.then((result) => {
				const invites = result.data.invites ?? [];
				this.setState({ invites, isLoadingInvites: false });
			})
			.catch((error) => {
				this.setState({ isLoadingInvites: false });
				console.log(error.response);
			});
	}

	MainCustomer() {
		var QRCode = require("qrcode.react");

		return (
			<div>

				{
					this.state.dashboard.generalagreement != null
						?
							this.state.dashboard.generalagreement.status == "request" ?
								<div className="neo-card">
									<div className="neo-qr-container2">
										<div className="neo-qr-container">
											<p
												className="neo-text"
												style={{color: "red", marginBottom: 0,  marginTop: 0, maxWidth: "400px" }}
											>
												Deine Rahmenvertraganfrage wird derzeit noch bearbeitet.
											</p>
										</div>
									</div>
								</div>
								:
								this.state.dashboard.generalagreement.status == "waiting" ?
									<div className="neo-card">
										<div className="neo-qr-container2">
											<div className="neo-qr-container">
												<p
													className="neo-text"
													style={{color: "red", marginBottom: 0,  marginTop: 0, maxWidth: "400px" }}
												>
													Sende uns den Rahmenvertrag zur Bearbeitung zu.
												</p>
											</div>
										</div>
									</div>
									:
									null
						:
						null
				}

				<div className="neo-card">
					<div className="neo-qr-container2">
						<div className="neo-qr-container">
							<QRCode
								className="neo-qr"
								value={this.state.dashboard.uuid ?? ""}
								renderAs="svg"
							/>
						</div>
					</div>

					<p
						className="neo-text"
						style={{ marginBottom: 0, maxWidth: "400px" }}
					>
						Lassen Sie diesen Code von einem Verkäufer scannen, um ein
						Leasingangebot zu erhalten.
					</p>
				</div>

				{this.state.invites.map((invite) => this.CompanyInvitationCard(invite))}

				<MenuButton
					title="Angebote"
					icon="Menu_Offers.png"
					onClick={this.showOffers}
					badge={this.state.dashboard.offerCount.toString()}
				/>
				<MenuButton
					title="Anfragen"
					icon="Menu_Offers.png"
					onClick={this.showCustomerDirectOffers}
					badge={this.state.dashboard.offerRequestCount.toString()}
				/>
				<MenuButton
					title="Händler in der Nähe"
					icon="Menu_Shops.png"
					onClick={this.showShops}
				/>
				<MenuButton
					title="Leasing Vorteilsrechner"
					icon="Menu_Offers.png"
					onClick={this.showCalc}
				/>
				<MenuButton
					title="Mein Profil"
					icon="Menu_Profile.png"
					onClick={this.showProfile}
				/>
				<MenuButton
					title="Mein Unternehmen / Mitarbeiter einladen"
					icon="Menu_Profile.png"
					onClick={this.showMyCompany}
				/>
				<MenuButton
					title="Kontakt"
					icon="Menu_Contact.png"
					onClick={this.showContact}
				/>
				<MenuButton
					title="Versicherung / Inspektionen / Schadensmeldung"
					icon="Menu_Contact.png"
					onClick={this.showInsurancePage}
				/>
				<MenuButton
					title="Versicherungsbedingungen Dienstfahrrad"
					icon="Menu_Contact.png"
					onClick={() => this.showInsuranceBedingungenPage("dienstfahrrad")}
				/>
				<MenuButton
					title="Versicherungsbedingungen Poolfahrräder"
					icon="Menu_Contact.png"
					onClick={() => this.showInsuranceBedingungenPage("poolfahrrad")}
				/>

				<div className="neo-spacer20" />

				<button className="neo-button" onClick={this.logout}>
					Abmelden
				</button>
			</div>
		);
	}

	MainVendor() {
		return (
			<div>
				<div className="neo-card">
					<div className="neo-qr-container2">
						<div className="neo-qr-container">
							<div className="neo-qr-scanButton" onClick={this.showQRScan} />
						</div>
					</div>

					<p
						className="neo-text"
						style={{ marginBottom: 0, maxWidth: "400px" }}
					>
						Scannen Sie jetzt einen Interessenten-Code, um ein Angebot zu
						erstellen.
					</p>
				</div>

				<MenuButton
					title="Leasinganfragen"
					icon="Menu_Offers.png"
					onClick={this.showOffers}
					badge={this.state.dashboard.offerCount.toString()}
				/>
				<MenuButton
					title="Direktanfragen von Kunden"
					icon="Menu_Offers.png"
					onClick={this.showVendorDirectOffers}
					badge={this.state.dashboard.offerRequestCount.toString()}
				/>
				<MenuButton
					title="Übergaben"
					icon="Menu_Offers.png"
					onClick={this.showVendorDirectHandovers}
					badge={this.state.dashboard.offerRequestCount.toString()}
				/>
				<MenuButton
					title="Ausweisprüfung"
					icon="Menu_IDCheck.png"
					onClick={this.showIDCheck}
					badge={this.state.dashboard.idCardCheckCount.toString()}
				/>

				<MenuButton
					title="Versicherung / Inspektionen / Schadensmeldung"
					icon="Menu_Contact.png"
					onClick={this.showInsurancePage}
				/>

				<MenuButton
					title="Versicherungsbedingungen Dienstfahrrad"
					icon="Menu_Contact.png"
					onClick={() => this.showInsuranceBedingungenPage("dienstfahrrad")}
				/>
				<MenuButton
					title="Versicherungsbedingungen Poolfahrräder"
					icon="Menu_Contact.png"
					onClick={() => this.showInsuranceBedingungenPage("poolfahrrad")}
				/>

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				<MenuButton
					title="Mein Profil"
					icon="Menu_Profile.png"
					onClick={this.showProfile}
				/>
				<MenuButton
					title="Kontakt"
					icon="Menu_Contact.png"
					onClick={this.showContact}
				/>

				<MenuButton
					title="Leasing Vorteilsrechner"
					icon="Menu_Offers.png"
					onClick={this.showCalc}
				/>

				<div className="neo-spacer20" />

				<button className="neo-button" onClick={this.logout}>
					Abmelden
				</button>

				<MultiStoreBanner />
			</div>
		);
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar showBackButton={false} />

				<div className="neo-content">
					{this.state.isLoading || this.state.isLoadingInvites
						? this.LoadingView()
						: this.isVendorPage()
						? this.MainVendor()
						: this.MainCustomer()}
				</div>

				<Footer />
			</div>
		);
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	logout() {
		let authManager = new AuthManager();
		authManager.logout();

		this.props.history.push(
			this.isVendorPage() ? "/vendor/welcome" : "/welcome"
		);
	}

	showOffers() {
		this.props.history.push(this.isVendorPage() ? "/vendor/offers" : "/offers");
	}

	showVendorDirectHandovers() {
		this.props.history.push("/vendor/offers/handover");
	}

	showVendorDirectOffers() {
		this.props.history.push("/vendor/offers/direct");
	}

	showCustomerDirectOffers() {
		this.props.history.push("/offers/direct");
	}

	showShops() {
		this.props.history.push("/shops");
	}

	showCalc() {
		this.props.history.push("/rechner/expertenrechner");
	}

	showContact() {
		this.props.history.push(
			this.isVendorPage() ? "/vendor/contact" : "/contact"
		);
	}

	showProfile() {
		this.props.history.push(
			this.isVendorPage() ? "/vendor/profile" : "/profile"
		);
	}

	showMyCompany() {
		this.props.history.push("/profile/companygroup");
	}

	showQRScan() {
		this.props.history.push("/vendor/scan");
	}

	showIDCheck() {
		this.props.history.push("/vendor/idCheck");
	}

	showInsurancePage() {
		window.open(INSURANCE_URL, "_blank");
	}

	showInsuranceBedingungenPage(comein) {
		window.open("https://system.gslease.de/versicherung_"+comein+".pdf", "_blank");
	}

	CompanyInvitationCard(invite) {
		return (
			<div
				className="neo-card"
				key={invite.id}
				style={{ paddingBottom: "10px" }}
			>
				<p className="neo-text" style={{ maxWidth: "500px" }}>
					Sie wurden eingeladen, dem Unternehmen {invite.company.name}{" "}
					beizutreten.
				</p>

				<NeoButton
					title="Beitreten"
					onClick={() => this.acceptInvite(invite)}
				/>
				<NeoButton
					title="Löschen"
					isDestructive={true}
					onClick={() => this.declineInvite(invite)}
				/>
			</div>
		);
	}

	acceptInvite(invite) {
		this.props.history.push("/invite/" + invite.id);
	}

	declineInvite(invite) {
		axios
			.get(BASE_URL + "/user/company/invite-decline/" + invite.id)
			.then((data) => {
				this.loadInvites();
			})
			.catch((error) => {});
	}
}

export default Main;

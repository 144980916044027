import React from "react";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import ShopCell from "./Shop/ShopCell";
import NeoDropdown from "./Dropdown/NeoDropdown";
import ErrorBox from "./Components/ErrorBox";
import axios from "axios";
import { BASE_URL } from "./Constants";
import Currency from "react-currency-formatter";
import LoadingSpinner from "./LoadingSpinner";
import ErrorView from "./ErrorView";
import NeoButton from "./Button/NeoButton";
import ConfirmationView from "./ConfirmationView";
import NeoCheckbox from "./Checkbox/NeoCheckbox";
import { nameForInsurance } from "./Constants";
import Cookies from "js-cookie";

class CreateOffer extends React.Component {
	state = {
		company_name: "",
		street: "",
		zip: "",
		city: "",
		hasCompanyGroup: false,

		rate: 0,
		insurance_fee: 0,
		insurance_available: null,
		objectName: "",
		objectNumber: "",
		objectManufacturer: "",
		objectPrice: "",
		leasingDuration: 36,
		accessories: "",
		accessoriesPrice: "",
		insurance: "none",

		errors: [],
		errorMessage: null,
		isSent: false,
		isLoading: true,
		noUserFound: false,
		isSending: false,
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.sendOffer = this.sendOffer.bind(this);
		this.loadUserInfo = this.loadUserInfo.bind(this);
		this.calculateLeasingRate = this.calculateLeasingRate.bind(this);
		this.recalculateLeasingRate = this.recalculateLeasingRate.bind(this);
		this.onRadioChange = this.onRadioChange.bind(this);
	}

	componentDidMount() {
		this.loadUserInfo();
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	loadUserInfo() {
		const { id } = this.props.match.params;

		axios
			.get(BASE_URL + "/vendor/user/" + id + "/base-info")
			.then((result) => {
				const profile = result.data.data;

				if (profile.address === null) {
					this.setState({
						company_name: profile.company_name ?? profile.email,
						isLoading: false,
					});
				} else {
					this.setState({
						company_name: profile.company_name ?? profile.email,
						street: profile.address.street ?? "",
						zip: profile.address.zip ?? "",
						city: profile.address.city ?? "",
						isLoading: false,
					});
				}

				if (profile.company !== undefined)
					this.setState({
						hasCompanyGroup: true,
					});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ noUserFound: true, isLoading: false });
				}
			});
	}

	sendOffer() {
		const { id } = this.props.match.params;

		this.setState({ isSending: true });

		let branchID = Cookies.get("CurrentStoreID");

		axios
			.post(
				BASE_URL +
					(this.state.useCompanyGroup
						? "/vendor/offer-create/company"
						: "/vendor/offer-create"),
				{
					object_name: this.state.objectName,
					object_price: this.objectPrice(),
					object_number: this.state.objectNumber,
					object_manufacturer: this.state.objectManufacturer,
					equipment: this.state.accessories,
					equipment_price: this.accessoriesPrice(),
					leasing_duration: this.state.leasingDuration,
					total_price: this.totalPrice(),
					user_uuid: id,
					insurance: this.state.insurance_available
						? this.state.insurance
						: "none",
					insurance_fee: this.state.insurance_fee,
					branch_id: branchID,
				}
			)
			.then(() => {
				this.setState({ isSending: false, isSent: true });
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	onRadioChange(event) {
		this.setState({
			insurance: event.target.value,
			rate: 0,
			insurance_fee: 0,
			insurance_available: null,
		});
	}

	calculateLeasingRate(duration) {
		axios
			.post(BASE_URL + "/vendor/calc-rate", {
				leasing_duration: duration,
				total_price: this.totalPrice(),
				insurance: this.state.insurance,
			})
			.then((result) => {
				const data = result.data;
				console.log(data);
				this.setState({
					rate: data.rate,
					insurance_fee: data.insurance,
					insurance_available: data.insurance_available,
				});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});

		console.log({
			leasing_duration: duration,
			total_price: this.totalPrice(),
			insurance: this.state.insurance,
		});
	}

	recalculateLeasingRate() {
		this.calculateLeasingRate(this.state.leasingDuration);
	}

	handleChange(event) {
		const value = event.target.value;

		this.setState({
			...this.state,
			[event.target.name]: value,
			errors: [],
		});

		if (event.target.name === "objectPrice") {
			this.setState({ rate: 0, insurance_fee: 0, insurance_available: null });
		}
	}

	handleChangeDuration = (duration) => {
		this.setState({ leasingDuration: duration.value });

		this.calculateLeasingRate(duration.value);
	};

	render() {
		return (
			<div className="neo-app">
				<NavigationBar />

				<div className="neo-content">
					<p className="neo-bar-title">Angebot erstellen</p>

					{this.state.isLoading ? (
						this.LoadingView()
					) : this.state.noUserFound === true ? (
						<ErrorView message="Dieser Interessent konnte nicht gefunden werden." />
					) : this.state.isSent ? (
						<ConfirmationView
							text="Sie haben das Angebot erfolgreich an den Kunden übermittelt."
							icon="confirmation.png"
						/>
					) : (
						this.OfferForm()
					)}
				</div>

				<Footer />
			</div>
		);
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	OfferForm() {
		return (
			<div>
				<ShopCell
					title={this.state.company_name}
					subtitle1={this.state.street}
					subtitle2={this.state.zip + " " + this.state.city}
					uuid={"UUID"}
					disableClick={true}
				/>

				<div className="neo-spacer20" />

				<input
					type="text"
					placeholder="Name des Leasingobjekts"
					name="objectName"
					value={this.state.objectName}
					onChange={this.handleChange}
				/>
				<input
					type="text"
					placeholder="Nummer des Leasingobjekts (optional)"
					name="objectNumber"
					value={this.state.objectNumber}
					onChange={this.handleChange}
				/>
				<input
					type="text"
					placeholder="Hersteller"
					name="objectManufacturer"
					value={this.state.objectManufacturer}
					onChange={this.handleChange}
				/>
				<input
					type="text"
					placeholder="Preis des Leasingobjekts netto"
					name="objectPrice"
					value={this.state.objectPrice}
					onChange={this.handleChange}
				/>

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				<textarea
					placeholder="Zubehör ohne Arbeitsleistung/Auslieferungskosten (optional)"
					name="accessories"
					value={this.state.accessories}
					onChange={this.handleChange}
				/>
				<input
					type="text"
					placeholder="Preis des Zubehörs ohne Arbeitsleistung/Auslieferungskosten (optional)"
					name="accessoriesPrice"
					value={this.state.accessoriesPrice}
					onChange={this.handleChange}
				/>

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				<NeoDropdown
					title="Leasingdauer"
					items={[{ name: "36", value: 36 }]}
					value={this.state.leasingDuration}
					onChangeValue={this.handleChangeDuration}
					trailingString="Monate"
				/>

				<div className="neo-card">
					<p className="neo-smallTitle">Versicherung</p>
					<p className="neo-text">
						Wählen Sie aus, ob Sie eine Versicherung für das Leasingobjekt
						buchen möchten:
					</p>

					<div
						className="neo-card-cell"
						onClick={() => this.setState({ insurance: "none" })}
					>
						<input
							type="radio"
							id="none"
							name="insurance"
							value="none"
							checked={this.state.insurance === "none"}
							onChange={this.onRadioChange}
						/>
						<label for="none" className="neo-text">
							<span>Keine Versicherung</span>
						</label>
					</div>
					<div
						className="neo-card-cell"
						onClick={() => this.setState({ insurance: "business_and_private" })}
					>
						<input
							type="radio"
							id="business_and_private"
							name="insurance"
							value="business_and_private"
							checked={this.state.insurance === "business_and_private"}
							onChange={this.onRadioChange}
						/>
						<label for="business_and_private" className="neo-text">
							<span>Versicherung mit 1%-Regelung</span>
						</label>
					</div>

					<div
						className="neo-card-cell"
						onClick={() => this.setState({ insurance: "only_business" })}
					>
						<input
							type="radio"
							id="only_business"
							name="insurance"
							value="only_business"
							checked={this.state.insurance === "only_business"}
							onChange={this.onRadioChange}
						/>
						<label for="only_business" className="neo-text">
							<span>Versicherung ohne 1%-Regelung</span>
						</label>
					</div>
				</div>

				<div className="neo-card">
					<p className="neo-mediumTitle" style={{ marginBottom: 0 }}>
						Gesamt:{" "}
						<Currency quantity={this.totalPrice()} currency="EUR" decimal="," />
					</p>

					<div className="neo-card-cell" style={{ marginTop: "20px" }}>
						<label for="none" className="neo-text">
							<span>Leasingrate</span>
							{this.state.rate === 0 ? (
								<span style={{ color: "gray" }}>Berechnung durchführen...</span>
							) : (
								<span style={{ color: "gray" }}>
									<Currency
										quantity={this.state.rate}
										currency="EUR"
										decimal=","
									/>{" "}
									mtl.
								</span>
							)}
						</label>
					</div>

					<div className="neo-card-cell">
						<label for="none" className="neo-text">
							<span>
								Gewählte Versicherung: {nameForInsurance(this.state.insurance)}
							</span>
							{this.state.insurance_available === null ? (
								<span style={{ color: "gray" }}>Berechnung durchführen...</span>
							) : this.state.insurance_available ? (
								<span style={{ color: "gray" }}>
									<Currency
										quantity={this.state.insurance_fee}
										currency="EUR"
										decimal=","
									/>{" "}
									mtl.
								</span>
							) : (
								<span style={{ color: "gray" }}>
									Versicherung nicht verfügbar
								</span>
							)}
						</label>
					</div>

					<NeoButton title="Berechnen" onClick={this.recalculateLeasingRate} />
				</div>

				{this.ErrorsView()}

				{this.state.hasCompanyGroup && (
					<div>
						<div className="neo-spacer20" />
						<div className="neo-spacer20" />

						<NeoCheckbox
							showTermsButton={true}
							name="useCompanyGroup"
							label="Diese Anfrage im Namen der Unternehmensgruppe stellen"
							value={this.state.useCompanyGroup}
							onChange={this.handleCheckbox}
						/>

						<p className="neo-text" id="leading">
							Der Kunde und dessen Arbeitgeber erhalten das Angebot. Nur der
							Arbeitgeber kann es annehmen oder ablehnen.
						</p>
					</div>
				)}

				<NeoButton
					title="Angebot senden"
					showLoadingState={this.state.isSending}
					onClick={this.sendOffer}
					disabled={
						this.state.objectName.length === 0 ||
						this.state.objectManufacturer.length === 0 ||
						this.state.objectPrice.length === 0
					}
				/>
			</div>
		);
	}

	totalPrice() {
		let objectPrice = this.state.objectPrice ? this.state.objectPrice : "0";
		objectPrice = objectPrice.replace(".", "");
		objectPrice = objectPrice.replace(",", ".");

		let accessoriesPrice = this.state.accessoriesPrice
			? this.state.accessoriesPrice
			: "0";
		accessoriesPrice = accessoriesPrice.replace(".", "");
		accessoriesPrice = accessoriesPrice.replace(",", ".");

		return parseFloat(objectPrice) + parseFloat(accessoriesPrice);
	}

	objectPrice() {
		let objectPrice = this.state.objectPrice ? this.state.objectPrice : "0";
		objectPrice = objectPrice.replace(".", "");
		objectPrice = objectPrice.replace(",", ".");

		return parseFloat(objectPrice);
	}

	accessoriesPrice() {
		let accessoriesPrice = this.state.accessoriesPrice
			? this.state.accessoriesPrice
			: "0";
		accessoriesPrice = accessoriesPrice.replace(".", "");
		accessoriesPrice = accessoriesPrice.replace(",", ".");

		return parseFloat(accessoriesPrice);
	}
}

export default CreateOffer;

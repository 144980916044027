import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import Footer from "../Components/Footer";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL, CONTRACT_URL, CUSTOMER_OFFER_SHARE_URL } from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import NeoEditField from "../EditField/NeoEditField";
import NeoButton from "../Button/NeoButton";
import NeoSelectField from "../SelectField/NeoSelectField";
import ErrorBox from "../Components/ErrorBox";

class VendorProfile extends React.Component {
	state = {
		isLoading: true,
		profile: [],
		isEditing: false,
		errors: null,
		errorMessage: null,
		isSending: false,
		showCopyBanner: false,
	};

	constructor(props) {
		super(props);

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.toggleEditMode = this.toggleEditMode.bind(this);
		this.saveProfile = this.saveProfile.bind(this);
		this.showChangePassword = this.showChangePassword.bind(this);
		this.showDeleteAccount = this.showDeleteAccount.bind(this);
		this.downloadQRCode = this.downloadQRCode.bind(this);
		this.downloadQRFile = this.downloadQRFile.bind(this);
		this.downloadLogoFile = this.downloadLogoFile.bind(this);
		this.downloadContract = this.downloadContract.bind(this);
		this.copyCustomerOfferLinkCode1 =
			this.copyCustomerOfferLinkCode1.bind(this);
		this.copyCustomerOfferLinkCode2 =
			this.copyCustomerOfferLinkCode2.bind(this);
	}

	componentDidMount() {
		this.loadProfile();
	}

	downloadQRCode() {
		axios
			.get(BASE_URL + "/vendor/qr_code/" + this.state.profile.uuid, {
				responseType: "blob",
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "QR_Code.png");
				document.body.appendChild(link);
				link.click();
			})
			.catch(function (error) {});
	}

	downloadLogoFile() {
		axios
			.get(this.state.profile.logo_file, {
				responseType: "blob",
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "gslease_logo.png");
				document.body.appendChild(link);
				link.click();
			})
			.catch(function (error) {});
	}

	downloadQRFile() {
		axios
			.get(this.state.profile.qr_file, {
				responseType: "blob",
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "QR_Display.pdf");
				document.body.appendChild(link);
				link.click();
			})
			.catch(function (error) {});
	}

	downloadContract() {
		window.open(CONTRACT_URL, "_blank");
	}

	copyCustomerOfferLinkCode1() {
		var shareLinkCode =
			`<a href="` +
			CUSTOMER_OFFER_SHARE_URL +
			this.state.profile.uuid +
			`"target="_blank"><img src="https://gslease.de/banner/bike_leasing.jpg"></a>`;

		navigator.clipboard.writeText(shareLinkCode);

		setTimeout(
			function () {
				this.setState({ showCopyBanner: true });
			}.bind(this),
			600
		);

		setTimeout(
			function () {
				this.setState({ showCopyBanner: false });
			}.bind(this),
			3000
		);
	}

	copyCustomerOfferLinkCode2() {
		var shareLinkCode =
			`<a href="` +
			CUSTOMER_OFFER_SHARE_URL +
			this.state.profile.uuid +
			`" target="_blank">Objekt jetzt leasen</a>`;

		navigator.clipboard.writeText(shareLinkCode);

		setTimeout(
			function () {
				this.setState({ showCopyBanner: true });
			}.bind(this),
			600
		);

		setTimeout(
			function () {
				this.setState({ showCopyBanner: false });
			}.bind(this),
			3000
		);
	}

	loadProfile() {
		axios
			.get(BASE_URL + "/vendor/profile")
			.then((result) => {
				const profile = result.data.data;

				console.log(profile);

				if (profile.profile !== null) {
					this.setState({
						profile: {
							gender: profile.profile.gender ?? "male",
							firstname: profile.profile.firstname,
							lastname: profile.profile.lastname,
							web: profile.profile.web,
							telephone: profile.profile.telephone,
							contact_email: profile.profile.contact_email,
							cr_number: profile.profile.cr_number,
							sti_number: profile.profile.sti_number,
							company_name: profile.company_name,
							street: profile.address.street,
							zip: profile.address.zip,
							city: profile.address.city,
							qr_code: profile.qr_code.url,
							qr_file: profile.display,
							logo_file: profile.logo_file,
							uuid: profile.uuid,
						},
						isLoading: false,
					});
				} else {
					this.setState({
						isLoading: false,
					});
				}
			})
			.catch(() => {});
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	handleFieldChange(event) {
		const value = event.target.value;
		const name = event.target.name;

		this.setState((prevState) => ({
			profile: {
				...prevState.profile,
				[name]: value,
			},
		}));
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	Profile() {
		var QRCode = require("qrcode.react");
		var shareLinkCode1 =
			`<a href="` +
			CUSTOMER_OFFER_SHARE_URL +
			this.state.profile.uuid +
			`"target="_blank"><img src="https://gslease.de/banner/bike_leasing.jpg"></a>`;

		var shareLinkCode2 =
			`<a href="` +
			CUSTOMER_OFFER_SHARE_URL +
			this.state.profile.uuid +
			`" target="_blank">Objekt jetzt leasen</a>`;

		return (
			<div>
				<div
					className="copyBanner"
					style={{
						opacity: this.state.showCopyBanner ? 1 : 0,
						display: !this.state.showCopyBanner && "none",
					}}
				>
					Code wurde in Ihre Zwischenablage kopiert.
				</div>

				<p className="neo-bar-title">Mein Profil</p>

				<div className="neo-card" style={{ paddingBottom: 0 }}>
					<div className="neo-qr-container2">
						<div className="neo-qr-container">
							<QRCode
								className="neo-qr"
								style={{ maxWidth: "100px", padding: "10px" }}
								value={
									"https://app.gslease.de/welcome/" + this.state.profile.uuid
								}
								renderAs="svg"
							/>
						</div>
					</div>

					<p className="neo-text" style={{ maxWidth: "400px" }}>
						Das ist Ihr persönlicher QR-Code, den Sie als Poster bzw. Plakat in Ihren Geschäftsräumen
						aufhängen.
					</p>

					<NeoButton title="Herunterladen" onClick={this.downloadQRCode} />

					<p className="neo-text" style={{ maxWidth: "400px" }}>
						Dies ist Ihr persönlicher QR-Code, den Sie als Poster bzw. Plakat in
						Ihren Geschäftsräumen aufhängen können
					</p>

					<NeoButton title="Herunterladen" onClick={this.downloadQRFile} />

					<p className="neo-text" style={{ maxWidth: "400px" }}>
						Das ist unser Logo, das Sie als Marke auf Ihrer Website veröffentlichen.
					</p>

					<NeoButton title="Herunterladen" onClick={this.downloadLogoFile} />

					<NeoButton
						title="Händlervertrag ansehen"
						onClick={this.downloadContract}
					/>
				</div>

				<div className="neo-card" style={{ paddingBottom: 0 }}>
					<p className="neo-text" style={{ maxWidth: "400px" }}>
						Nutzen Sie den folgenden Code und hinterlegen Sie diesen auf Ihrer
						Webseite
					</p>

					<p
						className="neo-text"
						style={{ maxWidth: "400px", fontSize: "13px" }}
					>
						{shareLinkCode1}
					</p>

					<NeoButton
						title="Code kopieren"
						onClick={this.copyCustomerOfferLinkCode1}
					/>

					<p className="neo-text" style={{ maxWidth: "400px" }}>
						alternativ können Sie auch den folgenden Link nutzen
					</p>

					<p
						className="neo-text"
						style={{ maxWidth: "400px", fontSize: "13px" }}
					>
						{shareLinkCode2}
					</p>

					<NeoButton
						title="Code kopieren"
						onClick={this.copyCustomerOfferLinkCode2}
					/>
				</div>

				<NeoEditField
					label="Firma"
					name="company_name"
					value={this.state.profile.company_name ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="HR-Nummer"
					name="cr_number"
					value={this.state.profile.cr_number ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Ust-ID"
					name="sti_number"
					value={this.state.profile.sti_number ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Straße"
					name="street"
					value={this.state.profile.street ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="PLZ"
					name="zip"
					value={this.state.profile.zip ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Ort"
					name="city"
					value={this.state.profile.city ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>

				<div className="neo-spacer20" />

				<NeoSelectField
					name="gender"
					label="Geschlecht"
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
					value={this.state.profile.gender ?? "male"}
					items={[
						<option key="male" value="male">
							Männlich
						</option>,
						<option key="female" value="female">
							Weiblich
						</option>,
						<option key="diverse" value="diverse">
							Divers
						</option>,
					]}
				/>

				<NeoEditField
					label="Vorname"
					name="firstname"
					value={this.state.profile.firstname ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Nachname"
					name="lastname"
					value={this.state.profile.lastname ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>

				<div className="neo-spacer20" />

				<NeoEditField
					label="Webseite"
					name="web"
					value={this.state.profile.web ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Telefon"
					name="telephone"
					value={this.state.profile.telephone ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>
				<NeoEditField
					label="Kontakt E-Mail"
					name="contact_email"
					value={this.state.profile.contact_email ?? ""}
					onChange={this.handleFieldChange}
					disabled={!this.state.isEditing}
				/>

				<div className="neo-spacer20" />

				<p className="neo-textSmall">
					Diese E-Mail-Adresse wird öffentlich für Interessenten auf der
					Händlerdetailseite angezeigt.
				</p>

				<div className="neo-spacer20" />
				<div className="neo-spacer20" />

				{this.ErrorsView()}

				<NeoButton
					showLoadingState={this.state.isSending}
					title={this.state.isEditing ? "Profil sichern" : "Profil bearbeiten"}
					onClick={this.toggleEditMode}
				/>

				{!this.state.isEditing ? (
					<div>
						<NeoButton
							title="Mein Passwort ändern"
							onClick={this.showChangePassword}
						/>
						<NeoButton
							title="Konto löschen ..."
							onClick={this.showDeleteAccount}
							isDestructive={true}
						/>
					</div>
				) : null}
			</div>
		);
	}

	toggleEditMode() {
		if (this.state.isEditing) {
			this.saveProfile();
		} else {
			this.setState({ isEditing: !this.state.isEditing });
		}
	}

	showChangePassword() {
		this.props.history.push("/vendor/profile/password");
	}

	showDeleteAccount() {
		this.props.history.push("/vendor/profile/delete");
	}

	saveProfile() {
		this.setState({ isSending: true });

		axios
			.post(BASE_URL + "/vendor/profile", {
				gender: this.state.profile.gender ?? "male",
				firstname: this.state.profile.firstname,
				lastname: this.state.profile.lastname,
				web: this.state.profile.web,
				telephone: this.state.profile.telephone,
				contact_email: this.state.profile.contact_email,
				cr_number: this.state.profile.cr_number,
				sti_number: this.state.profile.sti_number,
				company_name: this.state.profile.company_name,
				street: this.state.profile.street,
				zip: this.state.profile.zip,
				city: this.state.profile.city,
			})
			.then(() => {
				this.setState({
					isSending: false,
					isEditing: !this.state.isEditing,
					errors: null,
					errorMessage: null,
				});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	render() {
		return (
			<div className="neo-app">
				<NavigationBar title="Mein Profil" />
				<div className="neo-content">
					{this.state.isLoading ? this.LoadingView() : this.Profile()}
				</div>

				<Footer />
			</div>
		);
	}
}

export default VendorProfile;

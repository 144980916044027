import React from "react";
import "../Stylesheets/App.css";
import "../Stylesheets/neo.css";
import "./NeoDropdown.css";

import PropTypes from "prop-types";

class NeoDropdown extends React.Component {
	state = {
		isExpanded: false,
	};

	constructor(props) {
		super(props);
		this.state = {current: props.value}


		this.toggleExpansion = this.toggleExpansion.bind(this);
		this.changeSelection = this.changeSelection.bind(this);
	}

	render() {
		let c = typeof this.state.current == "string" ? this.state.current.toLowerCase() : this.state.current;
		return (
			<div

				style={{
					marginBottom: 30,
					flexDirection: "row",
				}}
				onClick={this.toggleExpansion}
			>

				{this.props.items.map((item) => (
					<p
						key={item.value}
						className="neo-dropdown-title"
						style={{verticalAlign: "middle", color: item.name.toLowerCase() == c ? "#fff" : "#587faa",backgroundColor: item.name.toLowerCase() == c ? "#587faa" : "rgba(255,255,255,.5)", width: window.innerWidth < 800 ? "47%" :"48%", display: "inline-block"}}
						onClick={() => this.changeSelection(item)}
					>

						<span style={{fontSize: window.innerWidth < 800 ? 12 : 14,paddingTop: 15, display: "block"}}>{item.name} {this.props.trailingString}</span>
					</p>
				))}
			</div>
		);
	}

	changeSelection(item) {
		this.setState({current: item.name})

		this.props.onChangeValue(item);
	}

	toggleExpansion() {
		this.setState({ isExpanded: !this.state.isExpanded });
	}
}

NeoDropdown.propTypes = {
	title: PropTypes.string,
	items: PropTypes.array,
	labeledItems: PropTypes.array,
	trailingString: PropTypes.string.isRequired,
};

export default NeoDropdown;

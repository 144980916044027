import React from "react";
import "../Stylesheets/App.css";
import "./NeoNavigationBar.css";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import AuthManager from "../Auth/AuthManager";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../Constants";
import Cookies from "js-cookie";

function NavigationBar(props) {
	let history = useHistory();
	let authManager = new AuthManager();

	const [isShowingMenu, setIsShowingMenu] = useState(false);
	const [stores, setStores] = useState([]);

	useEffect(() => {
		if (isVendorPage() && authManager.isAuthenticated()) {
			fetchStores();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function isVendorPage() {
		return history.location.pathname.includes("/vendor");
	}

	function showMainMenu() {
		history.push(isVendorPage() ? "/vendor" : "/");
	}

	function goBack() {
		history.goBack();
	}

	function toggleMenu() {
		setIsShowingMenu(!isShowingMenu);
	}

	function fetchStores() {
		axios
			.get(BASE_URL + "/vendor/branch")
			.then((result) => {
				const data = result.data.data;
				setStores(data);

				/*
				if (Cookies.get("CurrentStoreID") === undefined) {
					let store = getMainStore(data);
					Cookies.set("CurrentStoreName", store.name);
					Cookies.set("CurrentStoreID", store.id);

					setCurrentStoreName(store.name);
				} else {
					setCurrentStoreName(Cookies.get("CurrentStoreName"));
				}*/
			})
			.catch((error) => {
				console.log(error.response);
			});
	}

	function switchStore(store) {
		Cookies.set("CurrentStoreName", store.name);
		Cookies.set("CurrentStoreID", store.id);

		history.go(0);
	}

	function getMainStore(stores) {
		return stores.find((element) => {
			return element.is_main === 1;
		});
	}

	function showStoresList() {
		history.push("/vendor/stores");
	}

	if (isVendorPage()) {
		return (
			<div className="neo-bar-content-vendor">
				<div className="neo-bar">
					<div className="neo-bar-content">
						{props.showBackButton === false ? null : (
							<div className="neo-bar-backButton" onClick={goBack} />
						)}

						<div
							className="neo-bar-logo-alt"
							style={{
								backgroundImage: isVendorPage()
									? "url('/VendorLogoSmall.png'"
									: "url('/LogoSmall2.png'",
							}}
							onClick={showMainMenu}
						/>
					</div>
				</div>

				{authManager.isAuthenticated() && (
					<div>
						<div className="neo-bar-storeButton" onClick={toggleMenu}>
							<div style={{marginRight: 20}}>Filiale verwalten / hinzufügen</div>
							<div className="neo-bar-storeButton-profilePicture" id="bar">
								{(Cookies.get("CurrentStoreName") ?? "?").charAt(0).toUpperCase()}
							</div>
							<p>
								{Cookies.get("CurrentStoreName") ?? "Keine Filiale ausgewählt"}
							</p>
						</div>
					</div>
				)}

				{isShowingMenu && (
					<div
						className="userMenuWrapper"
						onClick={() => setIsShowingMenu(false)}
					>
						<div className="userMenu">
							{stores.map((store) => (
								<div
									key={store.id}
									className="userMenuRow"
									onClick={() => switchStore(store)}
								>
									<div className="neo-bar-storeButton-profilePicture">
										{store.name.charAt(0).toUpperCase()}
									</div>
									<p>{store.name}</p>
								</div>
							))}

							<div className="userMenuRow" onClick={showStoresList}>
								<p style={{ color: "rgba(48, 147, 252, 1)" }}>
									Filialen verwalten...
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}

	return (
		<div className="neo-bar">
			<div className="neo-bar-content">
				{props.showBackButton === false ? null : (
					<div className="neo-bar-backButton" onClick={goBack} />
				)}

				<div
					className="neo-bar-logo-alt"
					style={{
						backgroundImage: isVendorPage()
							? "url('/VendorLogoSmall.png'"
							: "url('/LogoSmall2.png'",
					}}
					onClick={showMainMenu}
				/>
			</div>
		</div>
	);
}

NavigationBar.propTypes = {
	showBackButton: PropTypes.bool,
};

export default NavigationBar;

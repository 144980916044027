import React from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/neo.css";
import Footer from "./Components/Footer";
import NavigationBar from "./NavigationBar/NavigationBar";
import axios from "axios";
import { BASE_URL } from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import ConfirmationView from "./ConfirmationView";
import NeoButton from "./Button/NeoButton";
import Cookies from "js-cookie";
import ErrorBox from "./Components/ErrorBox";

class UploadDocuments extends React.Component {
	state = {
		files: [],
		previews: [],
		errorMessage: null,
		errors: null,
		isSending: false,
		isSent: false,
	};

	constructor(props) {
		super(props);

		this.chooseFile = this.chooseFile.bind(this);
		this.onChange = this.onChange.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.uploadFiles = this.uploadFiles.bind(this);
	}

	render() {
		const { id } = this.props.match.params;

		return (
			<div className="neo-app">
				<NavigationBar title="Dokumente hochladen" />

				<div className="neo-content">
					{this.state.isSent ? (
						<ConfirmationView
							text="Die Dokumente wurden erfolgreich hochgeladen."
							icon="confirmation.png"
							route={"/vendor/offers/" + id}
						/>
					) : (
						this.Files()
					)}
				</div>

				<Footer />
			</div>
		);
	}

	LoadingView() {
		return <LoadingSpinner title="Laden..." />;
	}

	chooseFile() {
		this.state.inputElement.click();
	}

	onChange(event) {
		this.setState({
			files: this.state.files.concat(event.target.files[0]),
			previews: this.state.previews.concat(
				URL.createObjectURL(event.target.files[0])
			),
		});

		event.target.value = "";
	}

	deleteFile(index) {
		let files = [...this.state.files];

		if (index !== -1) {
			files.splice(index, 1);
			this.setState({ files: files });
		}

		let previews = [...this.state.previews];

		if (index !== -1) {
			previews.splice(index, 1);
			this.setState({ previews: previews });
		}
	}

	uploadFiles() {
		const { id } = this.props.match.params;

		this.setState({ isSending: true });

		const url = BASE_URL + "/vendor/offer-upload-documents";

		const formData = new FormData();

		this.state.files.map((file, index) =>
			index === 0
				? formData.append("file", file, file.name)
				: formData.append("file" + index + 1, file, file.name)
		);
		formData.append("offer_id", id);

		const config = {
			headers: {
				Accept: "application/json",
				"content-type": "multipart/form-data",
			},
		};

		return axios
			.post(url, formData, config)
			.then(() => {
				this.setState({
					isSent: true,
					isSending: false,
				});
			})
			.catch((error) => {
				if (error.response) {
					this.setState({
						isSending: false,
						errors: error.response.data.errors ?? null,
						errorMessage: error.response.data.message,
					});
				}
			});
	}

	ErrorsView() {
		const { errors } = this.state;
		const { errorMessage } = this.state;

		return errorMessage !== null ? (
			<ErrorBox errorMessage={errorMessage} errors={errors} />
		) : null;
	}

	Files() {
		return (
			<div>
				<p className="neo-mediumTitle" id="leading">
					Dokumente hochladen
				</p>
				<p className="neo-text" id="leading">
					Hier können Sie Rechnung, Abholschein, Abtretungsurkunde und andere
					Dokumente zu diesem Vertrag hochladen.
				</p>

				{this.state.files.map((file, index) => (
					<div key={file.url} style={{ marginBottom: "30px" }}>
						<div style={{ position: "relative" }}>
							<img
								className="neo-idCardPreview"
								src={this.state.previews[index]}
								alt=""
							/>

							<div className="neo-overlay">
								<div
									className="neo-deleteButton"
									onClick={() => this.deleteFile(index)}
								/>
							</div>
						</div>

						<p
							className="neo-callout"
							style={{
								margin: "0",
								padding: "0",
								marginTop: "-10px",
								textAlign: "left",
							}}
						>
							{file.name}
						</p>
					</div>
				))}

				<NeoButton title="Datei auswählen..." onClick={this.chooseFile} />

				<form>
					<input
						type="file"
						className="neo-hiddenUpload"
						accept=".png,.jpeg,.jpg,.pdf,.doc,.docx"
						onChange={this.onChange}
						ref={(input) => (this.state.inputElement = input)}
					/>
				</form>

				<div className="neo-spacer20" />

				<div className="neo-divider" />

				<div className="neo-spacer20" />

				{this.ErrorsView()}

				<NeoButton
					title="Hochladen"
					showLoadingState={this.state.isSending}
					onClick={this.uploadFiles}
					disabled={this.state.files.length === 0}
				/>
			</div>
		);
	}
}

export default UploadDocuments;
